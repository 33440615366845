import React, { useState, useEffect } from 'react';

export default function Ertesitesek() {
    const [isNewsletter, setIsNewsletter] = useState(true);
    const [isEmailNotification, setIsEmailNotification] = useState(true);

    const handleNewsletterChange = (e) => {
        setIsNewsletter(!isNewsletter);
        // Save !isNewsletter to database
    };

    const handleEmailNotificationChange = (e) => {
        setIsEmailNotification(!isEmailNotification);
        // Save !isNewsletter to database
    };


    return (
        <section className="card ">
        <h2>Értesítések</h2>

        <div className="notification-settings">
            <label>Email értesítések</label>
            <label className="toggle-switch">
                <input type="checkbox" checked={isEmailNotification} onChange={handleEmailNotificationChange} />
                <span className="slider"></span>
            </label>
        </div>

        <div className="notification-settings">
            <label>Hírlevél feliratkozás</label>
            <label className="toggle-switch">
                <input type="checkbox" checked={isNewsletter} onChange={handleNewsletterChange} />
                <span className="slider"></span>
            </label>
        </div>
    </section>

);
}
