import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import AuthButton from '../global-components/buttons/AuthButton';

export default function Registration({ loginAs, setLoginAs }) {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <HelmetProvider>
            <div className="page log-in-out">
                <Helmet>
                    <title>Registration</title>
                </Helmet>

                <div className="options">
                    <h2>I wish to register as:</h2>

                    <label htmlFor="userRole">
                        <input
                            type="radio"
                            id="userRole"
                            name="role"
                            value="user"
                            checked={loginAs === 'user'}
                            onChange={() => setLoginAs('user')}
                        />
                        <span>User</span>
                    </label>

                    <label htmlFor="adminRole">
                        <input
                            type="radio"
                            id="adminRole"
                            name="role"
                            value="admin"
                            checked={loginAs === 'admin'}
                            onChange={() => setLoginAs('admin')}
                        />
                        <span>Admin</span>
                    </label>
                </div>


                <div className="panel">
                    <h2>Registration</h2>

                    <form onSubmit={handleSubmit} method="post">
                        <div className="form-group">
                            <label htmlFor="email">Email Address:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email address"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone Number:</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                placeholder="Enter your phone number"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Create a password"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm_password">Confirm Password:</label>
                            <input
                                type="password"
                                id="confirm_password"
                                name="confirm_password"
                                placeholder="Confirm your password"
                                required
                            />
                        </div>

                        <div className="form-footer">
                            <AuthButton text="Register" btnType="register" />
                            <p>
                                Already have an account?{' '}
                                <Link to="/login" className="if-link">
                                    Sign in here
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </HelmetProvider>
    );
}
