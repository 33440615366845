import React, { useState, useEffect } from 'react';

import subscribers from '../../js/db_subscribers';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function UjFeliratkozoHozzaAdas() {

    const filteredClients = subscribers;

    return (
        <section className="card admin-card">
            <h2>Új feliratkozó hozzáadása</h2>

            <form>
                <div className="form-group">
                    <label htmlFor="client-name">Név</label>
                    <input type="text" id="client-name" />
                </div>

                <div className="form-group">
                    <label htmlFor="client-name">Email</label>
                    <input type="text" id="client-name" required />
                </div>

                <div className="form-group">
                    <label htmlFor="client-name">Csoportok</label>
                    <select>
                        <option value="">Összes csoport</option>
                    </select>
                </div>
                <ActionButton text="Feliratkozó hozzáadása" btnType={"add"} />
            </form>
        </section>
    );
}
