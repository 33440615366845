import React from 'react';
import { Link } from 'react-router-dom';

import { MdOutlineLogin as LoginIcon } from "react-icons/md";
import { MdOutlineLogout as LogoutIcon } from "react-icons/md";
import { MdAppRegistration as RegisterIcon } from "react-icons/md";

export default function AuthButton({ text, onClick, btnType, loginAs }) {
  const buttonTypes = {
    login: {
      to: `${loginAs}/dashboard`,
      icon: <LoginIcon className='icon' />
    },
    logout: {
      to: 'bejelentkezes',
      icon: <LogoutIcon className='icon mirrorY' />
    },
    register: {
      to: 'vezerlopult',
      icon: <RegisterIcon className='icon' />
    }
  };

  return (
    <Link
      to={`/${buttonTypes[btnType].to}`}
      className={`btn auth-btn`}
      onClick={onClick}
    >
      {buttonTypes[btnType].icon}
      {text}
    </Link>
  )
}
