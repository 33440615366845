import Beallitasok from "../menu/sections/Beallitasok";
import Adataim from "../menu/sections/Adataim";
import Kuldes from "../menu/sections/Kuldes";
import Feliratkozok from "../menu/sections/Feliratkozok";
import HirlevelArchivum from "../menu/sections/HirlevelArchivum";
import SzamlaArchivum from "../menu/sections/SzamlaArchivum";
import UjSzamla from "../menu/sections/UjSzamla";
import Arak from "../menu/sections/Arak";

const menu = [
    {
        name: 'Profil',
        isOpenByDefault: true,
        id: 'admin-profile-nav',
        items: [
            { itemName: 'Adataim', path: '/admin/vezerlopult', element: <Adataim />, },
            { itemName: 'Beállítások', path: '/admin/beallitasok', element: <Beallitasok />, }
        ],
        isActive: true,
    },
    {
        name: 'Hírlevél',
        isOpenByDefault: true,
        id: 'admin-newsletter-nav',
        items: [
            { itemName: 'Küldés', path: '/admin/hirlevel/kuldes', element: <Kuldes/>, },
            { itemName: 'Feliratkozók', path: '/admin/hirlevel/feliratkozok', element: <Feliratkozok />, },
            { itemName: 'Hírlevél archívum', path: '/admin/hirlevel/hirlevel-archivum', element: <HirlevelArchivum />, }
        ],
        isActive: true,
    },
    {
        name: 'Szolgáltatások',
        isOpenByDefault: true,
        id: 'admin-settings-nav',
        items: [
            { itemName: 'Árak', path: '/admin/szolgaltatasok/arak', element: <Arak />, }
        ],
        isActive: true,
    },
    {
        name: 'Felhasználók',
        isOpenByDefault: true,
        id: 'admin-users-nav',
        items: [
            { itemName: 'Ürlapok', path: '/admin/urlapok', element: '', },
            { itemName: 'Fájlok', path: '/admin/fajlok', element: '', },
            { itemName: 'Bérletek', path: '/admin/berletek', element: '', },
            { itemName: 'Számlák', path: '/admin/szamlak', element: '', },
            { itemName: 'Üzenetek', path: '/admin/uzenetek', element: '', }
        ],
        isActive: false,
    },
    {
        name: 'Számlák',
        isOpenByDefault: true,
        id: 'admin-billing-nav',
        items: [
            { itemName: 'Új számla', path: '/admin/szamlak/uj', element: <UjSzamla/> },
            { itemName: 'Számla archívum', path: '/admin/szamlak/szamla-archivum', element: <SzamlaArchivum />, }
        ],
        isActive: true,
    }
];

export default menu;
