import React from 'react';

import ArchivHirlevelek from '../cards/ArchivHirlevelek';

export default function HirlevelArchivum() {
    return (
        <>
            <div className='breadcrumbs'>Hírlevél / Archívum</div>

            <ArchivHirlevelek/>
        </>
    );
}
