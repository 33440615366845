import React, { useState } from 'react';

import ActionButton from '../../../../global-components/buttons/ActionButton';

// Mock data for archived newsletters
const newsletters = [
    { date: '2024-11-10', subject: 'November News' },
    { date: '2024-10-15', subject: 'October Update' },
    { date: '2024-09-20', subject: 'Back to School' },
    { date: '2024-08-01', subject: 'Summer Highlights' },
];

export default function ArchivHirlevelek() {
    // State variables for filters
    const [dateFilter, setDateFilter] = useState('');
    const [subjectFilter, setSubjectFilter] = useState('');

    // Filter the newsletters based on the filters
    const filteredNewsletters = newsletters.filter(newsletter => {
        const matchesDate = dateFilter === '' || newsletter.date.includes(dateFilter);
        const matchesSubject = subjectFilter === '' || newsletter.subject.toLowerCase().includes(subjectFilter.toLowerCase());

        return matchesDate && matchesSubject;
    });

    // Function to reset all filters
    const resetFilters = () => {
        setDateFilter('');
        setSubjectFilter('');
    };

    return (
        <section className="card">
            <h2>Archív hírlevelek</h2>

            {/* Filters Section */}
            <div className="service-filters">
                <input
                    type="text"
                    placeholder="Keresés dátum alapján (YYYY-MM-DD)..."
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                />

                <input
                    type="text"
                    placeholder="Keresés tárgy alapján..."
                    value={subjectFilter}
                    onChange={(e) => setSubjectFilter(e.target.value)}
                />
            </div>

            {/* Buttons for resetting filters and exporting data */}
            <div className="button-container">
                <ActionButton text="Szűrők nullázása" onClick={resetFilters} btnType={"reset"} />
                <ActionButton text="Export Excel-be" btnType={"export"} />
            </div>

            {/* Table to display filtered newsletters */}
            <div className="service-list">
                <table>
                    <thead>
                        <tr>
                            <th>Dátum</th>
                            <th>Tárgy</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredNewsletters.length > 0 ? (
                            filteredNewsletters.map((newsletter, index) => (
                                <tr key={index}>
                                    <td>{newsletter.date}</td>
                                    <td>{newsletter.subject}</td>
                                    <td className='action-cell'>
                                        <ActionButton btnType={"edit"} />
                                        <ActionButton btnType={"delete"} />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">Nincs találat</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
}
