import React, { useState } from 'react';
import bills from '../../js/db_bills';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function ArchivSzamlak() {
    // State variables for each filter
    const [emailFilter, setEmailFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [billNumberFilter, setBillNumberFilter] = useState('');
    const [serviceFilter, setServiceFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState(''); // 'paid' or 'unpaid'

    // Filter the bills based on all the filters
    const filteredBills = bills.filter(bill => {
        const matchesEmail = emailFilter === '' || bill.email.toLowerCase().includes(emailFilter.toLowerCase());
        const matchesName = nameFilter === '' || bill.customerName.toLowerCase().includes(nameFilter.toLowerCase());
        const matchesBillNumber = billNumberFilter === '' || bill.billNumber.toLowerCase().includes(billNumberFilter.toLowerCase());
        const matchesService = serviceFilter === '' || bill.services.join(", ").toLowerCase().includes(serviceFilter.toLowerCase());
        const matchesStatus = statusFilter === '' || (statusFilter === 'paid' ? bill.isPaid : !bill.isPaid);

        return matchesEmail && matchesName && matchesBillNumber && matchesService && matchesStatus;
    });

    // Function to reset all filters
    const resetFilters = () => {
        setEmailFilter('');
        setNameFilter('');
        setBillNumberFilter('');
        setServiceFilter('');
        setStatusFilter('');
    };

    return (
        <section className="card">
            <h2>Archív számlák</h2>

            {/* Filters Section */}
            <div className="service-filters">
                <input
                    type="text"
                    placeholder="Keresés számla szám alapján..."
                    value={billNumberFilter}
                    onChange={(e) => setBillNumberFilter(e.target.value)}
                />

                <input
                    type="text"
                    placeholder="Keresés email alapján..."
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />

                <input
                    type="text"
                    placeholder="Keresés név alapján..."
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                />


                <input
                    type="text"
                    placeholder="Keresés szolgáltatás alapján..."
                    value={serviceFilter}
                    onChange={(e) => setServiceFilter(e.target.value)}
                />

                <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                >
                    <option value="">Összes</option>
                    <option value="paid">Fizetett</option>
                    <option value="unpaid">Nem fizetett</option>
                </select>
            </div>

            {/* Buttons for resetting filters and exporting data */}
            <div className="button-container">
                <ActionButton text={"Szűrők nullázása"} onClick={resetFilters} btnType={"reset"} />
                <ActionButton text={"Export Excel-be"} btnType={"export"} />
                <ActionButton text={"NAV"} btnType={"taxOffice"} />
            </div>

            {/* Table to display filtered bills */}
            <div className="service-list">
                <table>
                    <thead>
                        <tr>
                            <th>Sz.sz</th>
                            <th>Email</th>
                            <th>Név</th>
                            <th>Szolgáltatások</th>
                            <th>Fizetett</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredBills.length > 0 ? (
                            filteredBills.map((bill, index) => (
                                <tr key={index}>
                                    <td>{bill.billNumber}</td>
                                    <td>{bill.email}</td>
                                    <td>{bill.customerName}</td>
                                    <td>{bill.services.join(", ")}</td>
                                    <td>{bill.isPaid ? 'Igen' : 'Nem'}</td>
                                    <td className='action-cell'>
                                        <ActionButton btnType={"edit"} />
                                        <ActionButton btnType={"delete"} />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">Nincs találat</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
}
