import React, { useState } from 'react';

import prices from '../../js/db_prices';

import { MdEdit as EditIcon } from "react-icons/md";
import { MdDelete as DeleteIcon } from "react-icons/md";

import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function SzolgaltatasokKezelese() {
    // State variables for filters
    const [searchTerm, setSearchTerm] = useState('');
    const [descriptionSearchTerm, setDescriptionSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [statusFilter, setStatusFilter] = useState('active'); // 'active' is selected by default

    // Get unique categories from the services data
    const categories = [...new Set(prices.map(service => service.category))];

    // Handle category change from the dropdown
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Function to reset all filters
    const resetFilters = () => {
        setSearchTerm('');
        setDescriptionSearchTerm('');
        setSelectedCategory('');
        setStatusFilter('active');
    };

    // Filter the services based on search term, description, category, and status
    const filteredServices = prices.filter(service => {
        const matchesName = searchTerm === '' || service.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDescription = descriptionSearchTerm === '' || service.description.toLowerCase().includes(descriptionSearchTerm.toLowerCase());
        const matchesCategory = selectedCategory === '' || service.category === selectedCategory;
        const matchesStatus = statusFilter === 'active' ? service.isActive : !service.isActive;

        return matchesName && matchesDescription && matchesCategory && matchesStatus;
    });

    return (
        <section className="card admin-card">
            <h2>Szolgáltatások kezelése</h2>

            <div className="service-filters">
                <input
                    type="text"
                    placeholder="Keresés név alapján..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <input
                    type="text"
                    placeholder="Keresés leírás alapján..."
                    value={descriptionSearchTerm}
                    onChange={(e) => setDescriptionSearchTerm(e.target.value)}
                />

                <select value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="">Összes Kategória</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </select>

                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={statusFilter === 'active'}
                            onChange={() => setStatusFilter(statusFilter === 'active' ? 'inactive' : 'active')}
                        />
                        Aktív
                    </label>
                </div>


            </div>

            <div className="button-container">
                <ActionButton text={"Szűrők nullázása"} onClick={resetFilters} btnType={"reset"} />
                <ActionButton text={"Export Excel-be"} btnType={"export"} />
                <ActionButton text={"Import Excel-ből"} btnType={"import"} />
            </div>

            <div className="service-list">
                <table>
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Leírás</th>
                            <th>Kategória</th>
                            <th>Ár (Ft)</th>
                            <th>Aktív</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredServices.length > 0 ? (
                            filteredServices.map((service, index) => (
                                <tr key={index}>
                                    <td title={service.name}>
                                        {service.name.length > 20 ? service.name.slice(0, 19) + "..." : service.name}
                                    </td>
                                    <td title={service.description}>
                                        {service.description.length > 20 ? service.description.slice(0, 19) + "..." : service.description}
                                    </td>
                                    <td>{service.category}</td>
                                    <td>{new Intl.NumberFormat('en-US', { useGrouping: true }).format(service.price).replace(/,/g, ' ')}</td>
                                    <td>{service.isActive ? 'Igen' : 'Nem'}</td>
                                    <td className='action-cell'>
                                        <ActionButton btnType={"edit"} />
                                        <ActionButton btnType={"delete"} />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">Nincs találat</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
}
