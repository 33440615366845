import React, { useState } from 'react';
import admin from '../../js/db_admin';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function Jelszo() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Handlers for controlled inputs
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    // Form submission handler with validation
    const handleSubmit = (e) => {
        e.preventDefault();

        // Ensure the new password and confirmation match
        if (newPassword !== confirmPassword) {
            alert("A megadott jelszavak nem egyeznek!");
            return;
        }

        // Ensure all fields are filled
        if (!currentPassword || !newPassword || !confirmPassword) {
            alert("Kérjük, töltse ki az összes mezőt!");
            return;
        }

        // Here, you would send the new password to the backend for updating
        alert("Jelszó sikeresen frissítve!");

        // Clear input fields after saving
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    return (
        <section className="card">
            <h2>Jelszó</h2>

            <form onSubmit={handleSubmit}>
                {/* Current password input */}
                <div className="form-group">
                    <label>Jelszó *</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={handleCurrentPasswordChange}
                    />
                </div>

                {/* New password input */}
                <div className="form-group">
                    <label>Új jelszó *</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                </div>

                {/* Confirm new password input */}
                <div className="form-group">
                    <label>Új jelszó megerősítése *</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />
                </div>

                {/* Save button */}
                <ActionButton text="Mentés" btnType={"save"} />
            </form>
        </section>
    );
}
