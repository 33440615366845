import React, { useState, useEffect } from 'react';

import { MdEdit as EditIcon } from "react-icons/md";
import { MdDelete as DeleteIcon } from "react-icons/md";

import subscribers from '../../js/db_subscribers';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function FeliratkozokKezelese() {

    const filteredClients = subscribers;

    return (
        <section className="card admin-card">
            <h2>Feliratkozók kezelése</h2>

            <div className="service-filters">
                <input
                    type="text"
                    placeholder="Keresés email alapján..."
                />

                <input
                    type="text"
                    placeholder="Keresés név alapján..."
                />

                <select>
                    <option value="">Összes csoport</option>
                </select>
            </div>

            <div className="button-container">
                <ActionButton text="Szűrők nullázás" btnType={"reset"} />
                <ActionButton text="Export Excel-be" btnType={"export"} />
            </div>

            <div className="service-list">
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Név</th>
                            <th>Csoportok</th>
                            <th>Műveletek</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClients.length > 0 ? (
                            filteredClients.map((client, index) => (
                                <tr key={index}>
                                    <td>{client.email}</td>
                                    <td>{client.name}</td>
                                    <td>{client.groups.join(", ")}</td>

                                    <td className='action-cell'>
                                        <ActionButton btnType={"edit"} />
                                        <ActionButton btnType={"delete"} />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">Nincs találat</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
}
