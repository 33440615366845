import React, { useState, useEffect } from 'react';
import subscribers from '../../js/db_subscribers';

import TextEditor from '../../Texteditor';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function Webmail() {
    const [mailTo, setMailTo] = useState('');

    useEffect(() => {
        const mailtoList = subscribers.map(item => item.email);
        setMailTo(mailtoList.join(","));
    }, []);

    return (
        <section className="card">
            <h2>Webmail</h2>

            <TextEditor />

            <div>
                <ActionButton text={"Küldés"} btnType={"send"} />
            </div>

        </section>
    );
}
