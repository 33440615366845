import React from 'react';
import { Link } from 'react-router-dom';

const translation = {
  tailored: {
    en: 'Tailored for Business Success',
    hu: 'Üzleti sikerhez igazítva',
  },
  tailoredDesc: {
    en: 'Discover a unified platform designed to optimize your business operations and drive growth.',
    hu: 'Fedezzen fel egy egységes platformot, amely az üzleti működés optimalizálására és növekedésére lett tervezve.',
  },
  documentation: {
    en: 'Effortless Documentation Management',
    hu: 'Kezelje dokumentumait hatékonyan',
  },
  documentationDesc: {
    en: 'Simplify your document creation, storage, and organization all in one place.',
    hu: 'Egyszerűsítse a dokumentumok létrehozását, tárolását és rendszerezését egy helyen.',
  },
  invoicing: {
    en: 'Invoicing Made Easy',
    hu: 'Gyors és egyszerű számlázás',
  },
  invoicingDesc: {
    en: 'Create and manage professional invoices with ease, ensuring accuracy and efficiency.',
    hu: 'Készítsen és kezeljen professzionális számlákat egyszerűen, biztosítva a pontosságot és hatékonyságot.',
  },
  power: {
    en: 'Unleash the Power of CRM Hub 365',
    hu: 'Szabadítsa fel a CRM Hub 365 erejét',
  },
  powerDesc: {
    en: 'Empower your business with a comprehensive CRM solution to manage and grow your customer relationships.',
    hu: 'Támogassa vállalkozását egy átfogó CRM megoldással az ügyfélkapcsolatok kezelésére és növelésére.',
  },
  viewPlans: {
    en: 'View Plans & Pricing',
    hu: 'Árak megtekintése',
  },
  track: {
    en: 'Track Financials with Confidence',
    hu: 'Kövesse nyomon pénzügyeit',
  },
  trackDesc: {
    en: 'Monitor cash flow and financial performance with intuitive tools for better decision-making.',
    hu: 'Figyelje a pénzforgalmat és a pénzügyi teljesítményt intuitív eszközökkel a jobb döntéshozatal érdekében.',
  },
  customer: {
    en: 'Seamless Customer Relationship Management',
    hu: 'Kezelje ügyfeleit zökkenőmentesen',
  },
  customerDesc: {
    en: 'Maintain and enhance customer satisfaction with tools that keep you connected and informed.',
    hu: 'Tartsa fenn és javítsa az ügyfél-elégedettséget olyan eszközökkel, amelyek kapcsolatban és informáltan tartják.',
  },
  findOutMore: {
    en: 'Find out more',
    hu: 'Tudjon meg többet',
  },
};

export default function Solutions({ language }) {
  return (
    <section className="section-container solutions">

      <Link to='success-hub' className="grid-item item-1">
        <div className='grid-content'>
          <h2>{translation.tailored[language]}</h2>
          <p className='description'>{translation.tailoredDesc[language]}</p>
          <p className='details'>{translation.findOutMore[language]}</p>
        </div>
      </Link>

      <Link to='invoicing-hub' className="grid-item item-3">
        <div className='grid-content'>
          <h2>{translation.invoicing[language]}</h2>
          <p className='description'>{translation.invoicingDesc[language]}</p>
          <p className='details'>{translation.findOutMore[language]}</p>
        </div>
      </Link>

      <Link to='documentation-hub' className="grid-item item-2">
        <div className='grid-content'>
          <h2>{translation.documentation[language]}</h2>
          <p className='description'>{translation.documentationDesc[language]}</p>
          <p className='details'>{translation.findOutMore[language]}</p>
        </div>
      </Link>

      <div className="middle-row">
        <Link to='power-hub' className="grid-item item-4">
          <div className='grid-content'>
            <h2>{translation.power[language]}</h2>
            <p className='description'>{translation.powerDesc[language]}</p>
            <button className='btn marketing-btn'>{translation.viewPlans[language]}</button>
          </div>
        </Link>

        <div className="comparison-column">
          <Link to='track-hub' className="grid-item item-5">
            <div className='grid-content'>
              <h2>{translation.track[language]}</h2>
              <p className='description'>{translation.trackDesc[language]}</p>
            </div>
          </Link>

          <Link to='customer-hub' className="grid-item item-6">
            <div className='grid-content'>
              <h2>{translation.customer[language]}</h2>
              <p className='description'>{translation.customerDesc[language]}</p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}
