import React, { useState } from 'react';

import prices from '../../js/db_prices';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function UjSzolgaltatasHozzaAdas() {
    // State variables for filters
    const [searchTerm, setSearchTerm] = useState('');
    const [descriptionSearchTerm, setDescriptionSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [statusFilter, setStatusFilter] = useState('active'); // 'active' is selected by default

    // Get unique categories from the services data
    const categories = [...new Set(prices.map(service => service.category))];

    // Handle category change from the dropdown
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Function to reset all filters
    const resetFilters = () => {
        setSearchTerm('');
        setDescriptionSearchTerm('');
        setSelectedCategory('');
        setStatusFilter('active');
    };

    // Filter the services based on search term, description, category, and status
    const filteredServices = prices.filter(service => {
        const matchesName = searchTerm === '' || service.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDescription = descriptionSearchTerm === '' || service.description.toLowerCase().includes(descriptionSearchTerm.toLowerCase());
        const matchesCategory = selectedCategory === '' || service.category === selectedCategory;
        const matchesStatus = statusFilter === 'active' ? service.isActive : !service.isActive;

        return matchesName && matchesDescription && matchesCategory && matchesStatus;
    });

    return (
        <section className="card admin-card">
            <h2>Új szolgáltatás hozzáadása</h2>

            <form>
                <div className="form-group">
                    <label htmlFor="service-name">Szolgáltatás Neve</label>
                    <input type="text" id="service-name" placeholder="Adja meg a szolgáltatás nevét" required />
                </div>

                <div className="form-group">
                    <label htmlFor="service-description">Leírás</label>
                    <textarea id="service-description" rows="3" placeholder="Adja meg a szolgáltatás leírását" required></textarea>
                </div>

                <div className="form-group">
                    <label htmlFor="service-category">Kategória</label>
                    <select id="service-category">
                        {categories.map((category, index) => (
                            <option key={index} value={category}>{category}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="service-price">Szolgáltatás Ára (Ft)</label>
                    <input type="number" id="service-price" placeholder="Adja meg az árat" min={0} step="1" required />
                </div>

                <div className="form-group">
                    <label htmlFor="service-active">Aktív</label>
                    <input type="checkbox" id="service-active" defaultChecked />
                </div>

                <ActionButton text={"Szolgáltatás hozzáadása"} btnType={"add"} />
            </form>
        </section>
    );
}
