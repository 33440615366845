import React from 'react';

const translation = {
    p1: {
        en: 'Your All-in-One Business Solution',
        hu: 'Mindent egyben, mindent egyhelyen',
    },
    h1: {
        en: 'CRM Hub 365',
        hu: 'CRM Hub 365',
    },
    h2: {
        en: 'Empowering Businesses Beyond CRM',
        hu: 'Több, mint egy egyszerű CRM',
    },
    p2: {
        en: 'Streamline your processes, enhance customer relationships, and unlock growth opportunities — all in one powerful platform.',
        hu: 'Egyszerűsítse folyamatait, javítsa az ügyfélkapcsolatokat és nyisson meg új lehetőségeket — mindezt egyetlen platformon.',
    },
    button: {
        en: 'View Plans & Pricing',
        hu: 'Árak megtekintése',
    },
}

export default function Hero({ language }) {
    return (
        <section className='section-container hero'>
            <div className='box hero-box'>
                <p>{translation.p1[language]}</p>
                <h1>{translation.h1[language]}</h1>
                <h2>{translation.h2[language]}</h2>
                <p>{translation.p2[language]}</p>
                <button className='btn marketing-btn'>{translation.button[language]}</button>
            </div>

            <div className='box'>
            </div>
        </section>
    )
}
