import React, { useState, useEffect } from 'react';

import Azonosito from '../cards/Azonosito';
import Nev from '../cards/Nev';
import Email from '../cards/Email';
import Telefonszam from '../cards/Telefonszam';
import SzamlazasiAdatok from '../cards/SzamlazasiAdatok';
import Jelszo from '../cards/Jelszo';

export default function Adataim() {

    return (
        <>
            <div className='breadcrumbs'>Profil / Adataim</div>

            <Azonosito />
            <Nev />
            <Email />
            <Telefonszam />
            <SzamlazasiAdatok />
            <Jelszo />
        </>
    );
}
