import React from 'react';

import HirlevelSajatEszkozrol from '../cards/HirlevelSajatEszkozrol';
import Webmail from '../cards/Webmail';

export default function Kuldes() {
    return (
        <>
            <div className='breadcrumbs'>Hírlevél / Küldés</div>
            
            <HirlevelSajatEszkozrol />
            <Webmail />
        </>
    );
}
