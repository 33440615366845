import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import './styles/App.css';

import Login from './components/website/Login';
import Registration from './components/website/Registration';

import AdminDashboard from './components/dashboards/admin/AdminDashboard';

import Home from './components/website/Home';

import menu from './components/dashboards/admin/js/menu';

import logo from "./img/logo200.png";

import { FaMobileAlt as MobileIcon } from "react-icons/fa";
import PageNotFound from './components/website/PageNotFound';

const translation = {
  banner: {
    en: "Your Partner in streamlining success",
    hu: "Partnere a siker kifinomult megvalósításában",
  },
  nav1: {
    about: {
      en: 'About',
      hu: 'Rólunk'
    },
    pricing: {
      en: 'Plans & Pricing',
      hu: 'Árak'
    },
    contact: {
      en: 'Contact',
      hu: 'Kapcsolat'
    },
    support: {
      en: 'Support',
      hu: 'Ügyfélszolgálat'
    },
    login: {
      en: 'Sign in',
      hu: 'Bejelentkezés'
    }
  },
  footer: {
    privacy: {
      en: 'Privacy',
      hu: 'Adatvédelem'
    },
    terms: {
      en: 'Terms & Conditions',
      hu: 'Felhasználási feltételek'
    },
    contact: {
      en: 'Contact',
      hu: 'Kapcsolat'
    },
    language: {
      en: 'Language',
      hu: 'Nyelv'
    },
    copyright: {
      en: "CRM Hub 365. All rights reserved.",
      hu: "CRM Hub 365. Minden jog fenntartva."
    }
  }
};

export default function App() {
  const [language, setLanguage] = useState('en');
  const [loginAs, setLoginAs] = useState('user');

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <Router>
      <div className='App'>
        <header>
          <div className='banner'>
            <h3>CRM Hub 365</h3>
            <p>{translation.banner[language]}</p>
          </div>

          <nav className='navigation-1'>
            <ul>
              <li>
                <Link className='menu-link' to='/'>
                  <img className='logo' src={logo} alt="Logo" />
                </Link>
              </li>
              <li>
                <Link className='menu-link' to='/about'>
                  {translation.nav1.about[language]}
                </Link>
              </li>
              <li>
                <Link className='menu-link' to='/plans-and-pricing'>
                  {translation.nav1.pricing[language]}
                </Link>
              </li>
              <li>
                <Link className='menu-link' to='/contact'>
                  {translation.nav1.contact[language]}
                </Link>
              </li>
              <li className='to-right'>
                <a className='menu-link' href='tel:+447421411763'>
                  <MobileIcon className="icon" /> 07 421 411 763
                </a>
              </li>
              <li>
                <Link className='menu-link' to='/support'>
                  {translation.nav1.support[language]}
                </Link>
              </li>
              <li>
                <Link to='/login'>
                  <button className='btn framed-btn'>
                    {translation.nav1.login[language]}
                  </button>
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        <main>
          <Routes>
            <Route path='*' element={<PageNotFound />} />
            <Route path='/' element={<Home language={language} />} />

            {menu.map(menu =>
              menu.items.map((item, index) =>
                menu.isActive && (
                  <Route
                    key={index}
                    path={item.path}
                    element={<AdminDashboard content={item.element} />}
                  />
                )
              )
            )}

            <Route path='/login' element={<Login loginAs={loginAs} setLoginAs={setLoginAs} />} />
            <Route path='/registration' element={<Registration loginAs={loginAs} setLoginAs={setLoginAs} />} />

            <Route path='/admin/dashboard' element={<AdminDashboard />} />
          </Routes>
        </main>

        <footer>
          <nav className='navigation-3'>
            <ul>
              <li>
                <Link to='/privacy'>
                  {translation.footer.privacy[language]}
                </Link>
              </li>
              <li>
                <Link to='/terms-and-conditions'>
                  {translation.footer.terms[language]}
                </Link>
              </li>
              <li>
                <Link to='/contact'>
                  {translation.footer.contact[language]}
                </Link>
              </li>
              <li className='to-right language-item'>
                <span>
                  {translation.footer.language[language]}:
                </span>
                <select className='language-select' value={language} onChange={handleLanguageChange}>
                  <option value='en'>EN</option>
                  <option value='hu'>HU</option>
                </select>
              </li>
            </ul>
          </nav>

          <div className="copyright">
            <p>
              &copy; {new Date().getFullYear()} {translation.footer.copyright[language]}
            </p>
          </div>
        </footer>
      </div>
    </Router>
  );
}
