import React, { useState, useEffect } from 'react';

import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function FiokNyelve() {
    const [currentLanguage, setCurrentLanguage] = useState('hun');
    const languageCodes = { hun: 'Magyar', eng: 'Angol' };

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
        // Save e.target.value to database
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <section className="card ">
            <h2>Fiók nyelve</h2>

            <form action="" onSubmit={handleSubmit}>

                <div className="form-group">
                    <label>Nyelv</label>
                    <select value={currentLanguage} onChange={handleLanguageChange}>
                        {Object.entries(languageCodes).map(([code, label]) => (
                            <option key={code} value={code}>{label}</option>
                        ))}
                    </select>
                </div>

                <ActionButton text="Mentés" btnType={"save"} />
            </form>
        </section>
    );
}
