import React from 'react';

import UjFeliratkozoHozzaAdas from '../cards/UjFeliratkozoHozzaAdas';
import FeliratkozokKezelese from '../cards/FeliratkozokKezelese';

export default function Feliratkozok() {
    return (
        <>
            <div className='breadcrumbs'>Hírlevél / Feliratkozók</div>

            <UjFeliratkozoHozzaAdas/>
            <FeliratkozokKezelese />
        </>
    );
}
