import React from 'react';

import FiokNyelve from '../cards/FiokNyelve';
import Ertesitesek from '../cards/Ertesitesek';

export default function Beallitasok() {
    return (
        <>
            <div className='breadcrumbs'>Profil / Beállítások</div>

            <FiokNyelve />
            <Ertesitesek />
        </>
    );
}
