import React from 'react';

import UjSzolgaltatasHozzaAdas from '../cards/UjSzolgaltatasHozzaAdas';
import SzolgaltatasokKezelese from '../cards/SzolgaltatasokKezelese';

export default function Arak() {
    return (
        <>
            <div className='breadcrumbs'>Szolgáltatások / Árak</div>

            <UjSzolgaltatasHozzaAdas />
            <SzolgaltatasokKezelese />

        </>
    );
}
