import React from 'react';
import UjSzamlaKeszites from '../cards/UjSzamlaKeszites';

export default function UjSzamla() {
    return (
        <>
            <div className='breadcrumbs'>Számlák / Új számla</div>

            <UjSzamlaKeszites/>
        </>
    );
}
