import React from 'react';

import { FaRegSave as SaveIcon } from "react-icons/fa";
import { FaPlus as AddIcon } from "react-icons/fa6";
import { FaFileExport as ExportIcon } from "react-icons/fa";
import { FaFileImport as ImportIcon } from "react-icons/fa";
import { FaEye as PreviewIcon } from "react-icons/fa";
import { FiRefreshCw as ResetIcon } from "react-icons/fi";
import { MdModeEditOutline as EditIcon } from "react-icons/md";
import { MdDeleteForever as DeleteIcon } from "react-icons/md";
import { LuSendHorizonal as SendIcon } from "react-icons/lu";

import taxOfficeLogo from '../../../img/nav.png';

export default function ActionButton({ text, onClick, btnType }) {
  const buttonTypes = {
    save: { icon: <SaveIcon className='icon' /> },
    add: { icon: <AddIcon className='icon' /> },
    export: { icon: <ExportIcon className='icon' /> },
    import: { icon: <ImportIcon className='icon' /> },
    preview: { icon: <PreviewIcon className='icon' /> },
    reset: { icon: <ResetIcon className='icon' /> },
    edit: { icon: <EditIcon className='icon' /> },
    delete: { icon: <DeleteIcon className='icon' /> },
    send: { icon: <SendIcon className='icon' /> },
    taxOffice: { icon: <img src={taxOfficeLogo} alt='nav-logo' className='icon'></img> }
  };

  return (
    <button
      className={`btn action-btn ${btnType === 'edit' || btnType === 'delete' ? 'mini-btn' : ""}`}
      onClick={onClick}
    >
      {buttonTypes[btnType].icon}
      {text}
    </button>
  )
}
