import React, { useState, useEffect } from 'react';
import { MdContentCopy as CopyIcon } from "react-icons/md";
import { FaCheck as OkIcon } from "react-icons/fa6";
import admin from '../../js/db_admin';

export default function Azonosito() {
    const [copied, setCopied] = useState(false);

    const copyCode = () => {
        const textToCopy = admin.adataim.azonosito.toLocaleUpperCase();
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                // Revert back to CopyIcon after 5 seconds
                setTimeout(() => setCopied(false), 5000);
            })
            .catch(err => {
                alert("Failed to copy text: ", err);
            });
    };

    return (
        <section className="card">
            <h2>Azonosító</h2>
            <form action="">
                <div className="form-group">
                    <div className='admin-code'>
                        {admin.adataim.azonosito.split('').map((char, index) => (
                            <span key={index}>{char}</span>
                        ))}
                        {copied ? (
                                <OkIcon title='Másolva!' className='icon' />
                        ) : (
                                <CopyIcon title='Vágólapra másol' className='icon' onClick={copyCode} />
                        )}
                    </div>
                </div>
            </form>
        </section>
    );
}
