import React, { useState } from 'react';

import SidebarSection from '../../dashboards/SidebarSection';
import menu from './js/menu';


export default function Sidebar() {
    const [activeMenu, setActiveMenu] = useState("");

    return (
        <aside className="sidebar">
            <h2 className="brand">Admin</h2>

            {menu.map((item, index) => (
                item.isActive && <SidebarSection
                    key={index}
                    sectionIndex={index}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                />
            ))}

        </aside>
    )
}
