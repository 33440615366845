import React from 'react';

const PageNotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
      textAlign: 'center',
      color: '#343a40',
      fontFamily: 'Arial, sans-serif',
    },
    title: {
      fontSize: '4rem',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    message: {
      fontSize: '1.5rem',
      marginBottom: '30px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '1rem',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = styles.buttonHover.backgroundColor;
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = styles.button.backgroundColor;
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Oops! The page you're looking for doesn't exist or undergoing maintenance.</p>
      <a
        href="/"
        style={styles.button}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        Go Back to Home
      </a>
    </div>
  );
};

export default PageNotFound;
