import React from 'react';
import ArchivSzamlak from '../cards/ArchivSzamlak';

export default function SzamlaArchivum() {
    return (
        <>
            <div className='breadcrumbs'>Számlák / Archívum</div>

            <ArchivSzamlak />
        </>
    );
}
