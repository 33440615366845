import React, { useState } from 'react';
import admin from '../../js/db_admin';
import ActionButton from '../../../../global-components/buttons/ActionButton';

export default function SzamlazasiAdatok() {
    const [billingName, setBillingName] = useState(admin.adataim.szamla_nev);
    const [billingAddress, setBillingAddress] = useState(admin.adataim.szamla_cim);
    const [nameInputValue, setNameInputValue] = useState("");
    const [addressInputValue, setAddressInputValue] = useState("");

    // Handlers for controlled inputs
    const handleNameInputChange = (e) => {
        setNameInputValue(e.target.value);
    };

    const handleAddressInputChange = (e) => {
        setAddressInputValue(e.target.value);
    };

    // Form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();

        // Trim and sanitize inputs
        const sanitizedName = nameInputValue.trim();
        const sanitizedAddress = addressInputValue.trim();

        // Update the billing information if inputs are not empty
        if (sanitizedName) setBillingName(sanitizedName);
        if (sanitizedAddress) setBillingAddress(sanitizedAddress);

        // Clear input fields after saving
        setNameInputValue('');
        setAddressInputValue('');
    };

    return (
        <section className="card">
            <h2>Számlázási adatok</h2>

            <form onSubmit={handleSubmit}>
                {/* Display current billing information */}
                <div className="form-group">
                    <label>Számlázási név</label>
                    <span>{billingName}</span>
                </div>

                <div className="form-group">
                    <label>Számlázási cím</label>
                    <span>{billingAddress}</span>
                </div>

                {/* Input fields for new billing information */}
                <div className="form-group">
                    <label>Új számlázási név *</label>
                    <input
                        type="text"
                        value={nameInputValue}
                        onChange={handleNameInputChange}
                    />
                </div>

                <div className="form-group">
                    <label>Új számlázási cím *</label>
                    <input
                        type="text"
                        value={addressInputValue}
                        onChange={handleAddressInputChange}
                    />
                </div>

                {/* Save button */}
                <ActionButton text="Mentés" btnType={"save"} />
            </form>
        </section>
    );
}
