import React, { useState } from 'react';

import admin from '../../js/db_admin';
import ActionButton from '../../../../global-components/buttons/ActionButton';

const pass = "alma";

export default function Telefonszam() {
    const [phone, setPhone] = useState(admin.adataim.tel);
    const [phoneInputValue, setPhoneInputValue] = useState("");
    const [passValue, setPassValue] = useState("");

    const handlePhoneInputChange = (e) => {
        setPhoneInputValue(e.target.value);
    };

    const handlePassValueChange = (e) => {
        setPassValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (passValue === pass) {
            const sanitizedPhone = phoneInputValue.trim();

            if (sanitizedPhone) {
                setPhone(sanitizedPhone);
                setPhoneInputValue('');
            }

            setPassValue("");
        } else {
            alert("Hibás jelszó");
        }
    };

    return (
        <section className="card">
            <h2>Telefonszám</h2>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Telefonszám</label>
                    <span>{phone}</span>
                </div>

                <div className="form-group">
                    <label>Új telefonszám *</label>
                    <input
                        type="tel"
                        value={phoneInputValue}
                        onChange={handlePhoneInputChange}
                    />
                </div>

                <div className="form-group">
                    <label>Jelszó *</label>
                    <input
                        type="password"
                        value={passValue}
                        onChange={handlePassValueChange}
                        placeholder="alma"
                    />
                </div>

                <ActionButton text="Mentés" btnType={"save"} />
            </form>
        </section>
    );
}
