import React from 'react';
import { Link } from 'react-router-dom';
import Hero from './sections/Hero';
import Solutions from './sections/Solutions';

const translation = {
    nav2: {
        companies: {
            en: 'For Companies',
            hu: 'Cégeknek'
        },
        clients: {
            en: 'For Clients',
            hu: 'Magánszemélyeknek'
        },
        partners: {
            en: 'For Partners',
            hu: 'Partnereknek'
        },
    }
};


export default function Home({ language }) {
    return (
        <div className='page home'>
            <Hero language={language} />
            <nav className='navigation-2'>
                <ul>
                    <button className='btn active-framed-btn'>{translation.nav2.companies[language]}</button>
                    <button className='btn framed-btn'>{translation.nav2.clients[language]}</button>
                    <button className='btn framed-btn'>{translation.nav2.partners[language]}</button>
                </ul>
            </nav>
            <Solutions language={language} />
        </div>
    )
}



