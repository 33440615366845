import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';

import { RxHamburgerMenu as HamburgerIcon } from "react-icons/rx";
import { IoIosHelpCircleOutline as HelpIcon } from "react-icons/io";
import { GoGear as GearIcon } from "react-icons/go";

export default function AdminDashboard({ content }) {
    const greetAdmin = () => {
        const time = new Date().getHours();
        if (time >= 5 && time <= 8) return 'Jó reggelt';
        if (time > 8 && time <= 18) return 'Üdvözöljük';
        if (time > 18 && time <= 23) return 'Jó estét';
        return 'Üdvözöljük';
    };

    //<h1>{greetAdmin()} Anett!</h1>
    //<AuthButton text={"Kijelentkezés"} btnType={"logout"} />

    return (
        <div className="dashboard">
            <Sidebar />

            <div className="content">
                <nav>
                    <ul>
                        <li>
                            <button>
                                <HamburgerIcon className='icon' />
                            </button>
                        </li>
                        <li>{greetAdmin()} Anett!</li>
                        <li className='to-right'>
                            <button>
                                <HelpIcon className='icon' /> Help
                            </button>
                        </li>
                        <li>
                            <button>
                                <GearIcon className='icon' />
                            </button>
                        </li>
                        <li>
                            <button className='profile-btn'>
                                <span className='icon '>A</span>
                            </button>
                        </li>
                    </ul>
                </nav>

                {/*<div className='breadcrumbs'>{breadcrumbs}</div>*/}

                {content}
            </div>
        </div >
    );
}
